import { Location } from '@reach/router';
import React from 'react';
import Logo from '../assets/swapp-logo.svg';

import Layout from '../components/layout';
import styles from './verified.module.css';

const IndexPage: React.SFC = () => (
  <Location>
    {({ location }) => (
      <Layout>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <h1>Регистрация подтверждена!</h1>
          <p>
            Напоминаем, что при регистрации вы указали{' '}
            {location.search.replace('?email=', '')} в качестве вашего почтового
            ящика.
          </p>
        </div>
      </Layout>
    )}
  </Location>
);

export default IndexPage;
